import { Route } from '@angular/router';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { AuthenticatedLayoutComponent } from './authenticated-layout/authenticated-layout.component';
import { AuthenticatedAuthGuard } from '@ami/angular/core/auth';

export const appRoutes: Route[] = [
  {
    path: 'public',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'login',
        loadChildren: () =>
          import('@ami/shared/login').then((mod) => mod.LoginRoutes),
      },
      {
        path: 'user-registration',
        loadChildren: () =>
          import('@ami/dealer/user-registration').then((mod) => mod.DealerUserRegistrationRoutes),
      }
    ],
  },
  {
    path: 'v1',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthenticatedAuthGuard],
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@ami/dealer/v1').then((mod) => mod.v1Routes),
      }
    ]
  },
  {
    path: 'a',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthenticatedAuthGuard],
    children: [
      {
        path: 'inventory',
        loadChildren: () => import('@ami/sales/inventory/shell-dealer').then(mod => mod.SalesInventoryShellDealerRoutes),
      },
    ],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'v1',
  },
  {
    path: '**',
    redirectTo: 'v1/dashboard',
  },
];

