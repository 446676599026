import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { OAuthService, TokenResponse } from "angular-oauth2-oidc";
import { BehaviorSubject, exhaustMap, tap, shareReplay, firstValueFrom, take, Observable } from "rxjs";

@Injectable({ providedIn: 'root' })
export class SingletonTokenRefreshService {
  private applicationInsights = inject(ApplicationInsights);
  private oauthService = inject(OAuthService);
  private refreshEvent = new BehaviorSubject<void>(undefined);
  private refresh$ = this.refreshEvent.pipe(
    exhaustMap(() => this.oauthService.refreshToken()),
    tap({
      error: ex => this.logRefreshError(ex)
    }),
    shareReplay()
  );

  public refreshToken() : Observable<TokenResponse> {
    this.refreshEvent.next();
    return this.refresh$.pipe(take(1));
  }

  private logRefreshError(ex: any) {
    //Detect that the refresh was rejected
    if(this.refreshExplicitlyRejected(ex)) {
      console.warn("Refresh auth token was rejected", ex);
      this.applicationInsights.trackEvent({ name: 'Refresh auth token rejected' });

      this.oauthService.logOut();
      return;
    }
    
    console.warn("Received an error attempting to refresh auth token", ex);
    this.applicationInsights.trackEvent({ name: 'Refresh auth token error' });

    var error: Error = ex instanceof Error ? ex : new Error("Refresh token error");
    this.applicationInsights.trackException({ exception: error });
  }

  refreshExplicitlyRejected(ex: any) : boolean {
    if(ex instanceof HttpErrorResponse) {
      return ex.status == 403;
    }

    return false;
  }
}
