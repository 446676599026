import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ErrorEvent } from './error-event';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlingService {
  public errorEvents$: Observable<ErrorEvent>;
  public errorEvents = new Subject<ErrorEvent>();

  constructor() {
    this.errorEvents$ = this.errorEvents.asObservable();
  }
}
