import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Toast } from 'bootstrap';
import { EventTypes } from '../toast-service/event-types';
import { ToastService } from '../toast-service/toast.service';

@Component({
  selector: 'ami-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class ToastComponent implements OnInit {
  public message = '';
  public eventType!: EventTypes;
  public eventTypes = EventTypes;

  constructor(private toastService: ToastService) { }
  toggleToast(visible: boolean) {
    const toasts: any[] = Array.from(document.querySelectorAll('.toast')).map(
      (toastNode) => new Toast(toastNode)
    );

    if (visible) {
      toasts.forEach((item) => { item.show() });
    } else {
      toasts.forEach((item) => { item.hide() });
    }
  }

  ngOnInit(): void {
    this.toastService.toastEvents$.subscribe((event) => {
      this.message = event.message;
      this.eventType = event.type;
      this.toggleToast(true);
    });

    this.toastService.dequeueToast$.subscribe((event) => {
      if (event) {
        this.toggleToast(false);
      }
    })
  }
}
