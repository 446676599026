import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CORE_UI } from '../core-ui.constant';
import { HttpRequestState, isLoadingState } from 'ngx-http-request-state';

@Component({
  selector: 'ami-loading',
  standalone: true,
  imports: [CORE_UI],
  templateUrl: './loading.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent implements OnChanges {
  @Input() model?: HttpRequestState<unknown>|null|undefined|boolean = null;

  public isLoading = false;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['model']) return;

    if(typeof this.model == 'boolean') {
      this.isLoading = this.model as boolean;
    }
    else {
      this.isLoading = this.model !== null && this.model !== undefined && isLoadingState(this.model);
    }
  }
}
