import { AuthStartupService } from '@ami/angular/core/auth';
import { ErrorHandlingService } from '@ami/angular/core/error-handling';
import { EventTypes, ToastService } from '@ami/angular/core/toasts';
import { CommonModule } from '@angular/common';
import { Component, enableProdMode } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ValdemortConfig, ValdemortModule } from 'ngx-valdemort';

@Component({
  standalone: true,
  imports: [RouterModule, ValdemortModule, CommonModule],
  selector: 'ami-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'dealer';

  constructor(
    private authStartup: AuthStartupService,
    private errorHandlingService: ErrorHandlingService,
    private toastService: ToastService,
    private valdemortConfig: ValdemortConfig
  ) {
    this.valdemortConfig.errorsClasses = 'invalid-feedback';
  }

  async ngOnInit() {
    await this.authStartup.initialize("/public/login");

    this.errorHandlingService.errorEvents$.subscribe(error => {
      this.toastService.queueToast(error.message, EventTypes.Error);
    });

    if (process.env['NODE_ENV'] === 'production') {
      enableProdMode();
    }
  }
}
