<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ title }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="mb-3">
    {{ contentText }}
  </div>
  <div>
    <ng-content></ng-content>
  </div>
</div>
<div class="modal-footer">
  <button 
    type="button" 
    class="btn {{ cancelButtonStyle }}" 
    (click)="cancel()">
    {{ cancelButtonText }}
  </button>
  <button
    type="button"
    class="btn {{ confirmButtonStyle }}"
    (click)="ok()"
    ngbAutofocus
  >
    <span>{{ confirmButtonText }}</span>
  </button>
</div>