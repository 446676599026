import { APP_INITIALIZER, EnvironmentProviders, Injector, effect, inject, makeEnvironmentProviders } from "@angular/core";
import { Router } from "@angular/router";
import { User, UserService } from "@ami/angular/core/auth";
import { AngularPlugin } from "@microsoft/applicationinsights-angularplugin-js";
import { ApplicationInsights, ITelemetryItem } from "@microsoft/applicationinsights-web";
import { ErrorHandlingService } from "libs/angular/core/error-handling/src/lib/error-handling-service/error-handling.service";

export function provideApplicationInsights(instrumentationKey: string, appVersion: string, appName: string): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: ApplicationInsights,
      useFactory: () => initializeApplicationInsights(instrumentationKey, appVersion, appName),
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => subscribeToUserContextChanges(),
      deps: [UserService, ApplicationInsights],
      multi: true,
    },
  ]);
}

function initializeApplicationInsights(instrumentationKey: string, appVersion: string, appName: string) {
  const router = inject(Router);

  const angularPlugin = new AngularPlugin();

  var disable = !(instrumentationKey && instrumentationKey.trim());

  if (disable) {
    console.log("Application Insights is disabled!");
  }

  const applicationInsights = new ApplicationInsights({
    config: {
      disableTelemetry: disable,
      disableExceptionTracking: disable,
      instrumentationKey: instrumentationKey ?? '',
      extensions: [angularPlugin],
      extensionConfig: {
        [angularPlugin.identifier]: {
          router: router,
          errorServices: [new ErrorHandlingService()],
        },
      },
    },
  });

  const telemetryInitializer = (envelope: ITelemetryItem): void => {
    if (!envelope) return;

    if (!envelope.tags) {
      envelope.tags = [];
    }

    envelope.tags['ai.cloud.role'] = appName;
    envelope.tags['ai.application.ver'] = appVersion;
  };

  applicationInsights.loadAppInsights();
  applicationInsights.addTelemetryInitializer(telemetryInitializer);

  return applicationInsights;
}

function subscribeToUserContextChanges() {
  const userService = inject(UserService);
  const applicationInsights = inject(ApplicationInsights);
  const injector = inject(Injector);

  return () => effect(() => setUser(userService.user(), applicationInsights), { injector : injector });
}

function setUser(user: User | null, appInsightsClient: ApplicationInsights) {
  if (user) {
    appInsightsClient.setAuthenticatedUserContext(user.userId);
  }
  else {
    appInsightsClient.clearAuthenticatedUserContext();
  }
}