import { OAuthService } from "angular-oauth2-oidc";
import { catchError, filter, map, merge, Observable, of, take, timeout } from "rxjs";

export function waitForAccessToken(oAuthService: OAuthService): Observable<string> {
    return merge(
        of(oAuthService.getAccessToken()).pipe(filter((token) => !!token)),
        oAuthService.events.pipe(
        filter((e) => e.type === 'token_received'),
        timeout(oAuthService.waitForTokenInMsec || 0),
        catchError(() => of(null)), // timeout is not an error
        map(() => oAuthService.getAccessToken())
        )
    ).pipe(
        take(1)
    );
}