import { computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AbstractControl, FormGroup } from '@angular/forms';

export function toValidInputSignal<T extends { [K in keyof T]: AbstractControl<any, any> }>(form: FormGroup<T>) {
  const formValueChanges = toSignal(form.valueChanges);

  const validInput = computed(() => {
    if (formValueChanges() && form.valid) {
      return form.getRawValue();
    }

    return null;
  });

  return validInput;
}
