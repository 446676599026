<ami-test-banner [showTestBanner]="showTestBanner"></ami-test-banner>
<nav class="navbar navbar-dark navbar-expand-lg bg-dark pb-0" data-bs-theme="dark">
  <div class="container-fluid" *ngIf="dealerUser() as user">
    <a class="navbar-brand" routerLink="/v1/dashboard" (click)="navigateTo('/v1/dashboard')">
      <img src="../../assets/ami_logo.png" alt="ami logo">
    </a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0" [ngClass]="{'display-flex-wrap': isMobileView()}">
        <li class="nav-item" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}"
            routerLink='/v1/quick-price-lookup/step1' (click)="navigateTo('/v1/quick-price-lookup/step1')">Price
            Lookup</a>
        </li>

        <li class="nav-item" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}" routerLink='/v1/quotes'
            (click)="navigateTo('/v1/quotes')">Quotes</a>
        </li>

        <li class="nav-item" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}" routerLink='/v1/orders'
            (click)="navigateTo('/v1/orders')">Orders</a>
        </li>

        <li class="nav-item" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}"
            routerLink='/v1/documents' (click)="navigateTo('/v1/documents')">Parts & Manuals</a>
        </li>

        <li class="nav-item" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}"
            routerLink='/a/inventory' (click)="navigateTo('/a/inventory')">Inventory</a>
        </li>

        <li class="nav-item" *ngIf="isUserDealerAdministrator()" [ngClass]="{'col-6 p-1': isMobileView()}">
          <a class="nav-link" [ngClass]="{'btn btn-primary text-black w-100' : isMobileView()}"
            routerLink='/v1/dealer-users' (click)="navigateTo('/v1/dealer-users')">Users</a>
        </li>
      </ul>

      <ul class="navbar-nav mb-2 mb-lg-0 align-items-center" [ngClass]="{'mt-3': isMobileView()}">
        <li class="nav-item dropdown">
          <div class="d-flex">
            <img class="img-thumbnail-table rounded mt-0 me-2" src="{{getDealerGroupAction.value()?.imageUrl}}" />

            <div *ngIf="user.dealerSettings.authorizedDealerGroups.length === 1">
              <label class="nav-item nav-link mb-0 text-truncate dealer-label">{{currentGroupName()}}</label>
            </div>

            <div *ngIf="user.dealerSettings.authorizedDealerGroups.length > 1">
              <div class="dropdown">
                <a class="btn btn-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  {{ currentGroupName()}}
                </a>
                <ul class="dropdown-menu" style="right: 0; left: auto;">
                  <li *ngFor="let group of user.dealerSettings.authorizedDealerGroups">
                    <a class="dropdown-item" (click)="changeDealerGroup(group)">{{group.name}}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="dropdown">
            <a class="btn btn-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown"
              aria-expanded="false">
              {{ user.name}}
            </a>
            <ul class="dropdown-menu" style="right: 50px; left: auto;"
              [ngClass]="{'position-absolute': isMobileView()}">
              <li><a class="dropdown-item" routerLink='/v1/change-password'
                  (click)="navigateTo('/v1/change-password')">Change Password</a></li>
              <li><a class="dropdown-item" (click)="logout()">Log Out</a></li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
