import { ToastComponent } from '@ami/angular/core/toasts';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'ami-public-layout',
  standalone: true,
  imports: [RouterModule, ToastComponent],
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent { }
