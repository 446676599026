import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { FormControlType } from './FormControlType';
import { filterValidForm } from './filterValidForm';

export function validFormSubmissionSource<
  TControl extends FormControlType<TControl>
>(form: FormGroup<TControl>): ValidFormSubmitter<TControl> {
  return new ValidFormSubmitter(form);
}

export class ValidFormSubmitter<TControl extends FormControlType<TControl>> {
  private submitTrigger = new Subject<void>();

  public validSubmisions$ = this.submitTrigger.pipe(filterValidForm(this.form));

  constructor(private form: FormGroup<TControl>) { }

  public submit() {
    this.submitTrigger.next();
  }
}
