import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class AmiValidators {
  public static notEmpty(control: AbstractControl): ValidationErrors | null {
    return (control.value || '').trim().length ? null : { notEmpty: true };
  }

  public static isNumeric(control: AbstractControl): ValidationErrors | null {
    const esNumerico = /^[0-9]+$/.test(control.value);
    if (!esNumerico) {
      return { notNumeric: true };
    }
    return null;
  }

  public static isAlphaNumeric(control: AbstractControl): ValidationErrors | null {
    const isAlphaNumeric = /^[a-zA-Z0-9_]+$/.test(control.value);
    if (!isAlphaNumeric) {
      return { notAlphaNumeric: true };
    }
    return null;
  }

  public static isAlphaNumericOrHyphens(control: AbstractControl): ValidationErrors | null {
    const isAlphaNumericOrHyphens = /^[a-zA-Z0-9_-]+$/.test(control.value);
    if (!isAlphaNumericOrHyphens) {
      return { isAlphaNumericOrHyphens: true };
    }
    return null;
  }

  public static mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
      return null;
    };
  }
}
