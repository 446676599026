import { DealerGroupSwapViewModel, RequiresPermissionDirective, UserService } from '@ami/angular/core/auth';
import { FeatureFlagDirective } from '@ami/angular/core/feature-flags';
import { ToastComponent } from '@ami/angular/core/toasts';
import { CORE_UI } from '@ami/angular/core/ui';
import { UserNotificationButtonComponent } from '@ami/management/user-notifications';
import { CommonModule } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Component, HostListener, OnInit, computed, signal } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { DealerUser } from 'libs/angular/core/auth/src/lib/user-service/user';
import { AppSettings } from '../app.settings';
import { DealerGroupService } from './dealer-group.service';
import { toSignal } from '@angular/core/rxjs-interop';

@Component({
  selector: 'ami-menu',
  standalone: true,
  imports: [
    CommonModule,
    RequiresPermissionDirective,
    ToastComponent,
    RouterModule,
    UserNotificationButtonComponent,
    CORE_UI,
    FeatureFlagDirective
  ],
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public showTestBanner = false;
  public isUserDealerAdministrator = signal<boolean>(false);
  public dealerUser = signal<DealerUser | undefined>(undefined);
  public currentGroupName = signal<string | undefined>(undefined);

  public getDealerGroupAction = this.dealerGroupService.getDealerGroupAction();
  public isMobileView = signal<boolean>(false);

  constructor(
    private userService: UserService,
    private router: Router,
    private appSettings: AppSettings,
    private dealerGroupService: DealerGroupService,
    private oauthService: OAuthService
  ) {
    this.showTestBanner = this.appSettings.showTestBanner;
    this.initDealerUser();
  }

  ngOnInit(): void {
    this.checkIfMobileView();
  }

  private initDealerUser() {
    const dealerUser = this.userService.user() as DealerUser;
    if (dealerUser) {
      this.dealerUser.set(dealerUser);
      this.isUserDealerAdministrator.set(dealerUser.dealerSettings.isAdmin);
      this.getDealerGroupAction.input.set(dealerUser.dealerSettings.dealerGroupId);

      this.setCurrentGroupName(dealerUser);
    }
  }

  private setCurrentGroupName(dealerUser: DealerUser) {
    const groups = dealerUser.dealerSettings.authorizedDealerGroups;
    const currentGroup = groups.filter((x: any) => x.id === dealerUser.dealerSettings.dealerGroupId);
    this.currentGroupName.set(currentGroup[0].name);
  }

  logout(): void {
    this.userService.logout();
  }

  async changeDealerGroup(group: DealerGroupSwapViewModel) {
    await this.oauthService.fetchTokenUsingGrant("refresh_token", {
      scope: this.oauthService.scope,
      refresh_token: this.oauthService.getRefreshToken()
    }, new HttpHeaders({ 'groupid': group.id }));

    await this.userService.loadUserProfile();

    this.router.navigate(['v1/dashboard']);
    setTimeout(() => { window.location.reload()});
  }

  navigateTo(url: string) {
    this.router.navigateByUrl(url);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobileView();
  }

  private checkIfMobileView() {
    this.isMobileView.set(window.matchMedia("(max-width: 991px)").matches);
  }
}
