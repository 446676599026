import { Injectable, Optional } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { catchError, filter, map, merge, mergeMap, Observable, of, switchMap, take, tap, throwError, timeout } from 'rxjs';
import { ErrorFormatterService, ErrorHandlingService, ErrorType } from '@ami/angular/core/error-handling';
import { SingletonTokenRefreshService, waitForAccessToken } from '@ami/angular/core/auth';
import { OAuthModuleConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class HttpInterceptorService implements HttpInterceptor {
  constructor(
    private errorHandlingService: ErrorHandlingService,
    private errorFormatterService: ErrorFormatterService,
    private tokenRefreshService: SingletonTokenRefreshService,
    private oAuthService: OAuthService,
    @Optional() private moduleConfig: OAuthModuleConfig
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    if (this.skipOAuth(req)) {
      return this.handle(req, next, null, true);
    }

    return waitForAccessToken(this.oAuthService).pipe(
      mergeMap((token) => this.handle(req, next, token, true))
    );
  }

  private skipOAuth(req: HttpRequest<any>) {
    const url = req.url.toLowerCase();

    return !this.moduleConfig ||
      !this.moduleConfig.resourceServer ||
      !this.checkUrl(url) ||
      !this.moduleConfig.resourceServer.sendAccessToken;
  }

  private handle(req: HttpRequest<any>, next: HttpHandler, token: string|null|undefined, retry: boolean): Observable<HttpEvent<any>> {
    if (token) {
      req = this.addAccessToken(token, req);
    }

    return next.handle(req).pipe(
      catchError((response: HttpErrorResponse) => {
        if(response.status == 401 && retry && token) {
          console.debug("Refresh & retry");
          return this.refreshAndRetry(req, next, response);
        }

        console.debug("skipping retry");
        return this.handleErrorAndThrow(response, req);
      })
    );
  }

  private addAccessToken(token: string, req: HttpRequest<any>) {
    const header = 'Bearer ' + token;
    const headers = req.headers.set('Authorization', header);
    req = req.clone({ headers });
    return req;
  }

  private refreshAndRetry(req: HttpRequest<any>, next: HttpHandler, originalError: HttpErrorResponse): Observable<HttpEvent<any>> {
    return this.tryRefreshToken().pipe(
      //don't retry if refresh failed
      switchMap(token => token
        ? this.handle(req, next, token, false)
        : this.handleErrorAndThrow(originalError, req)
      )
    );
  }

  private tryRefreshToken() : Observable<string|null> {
    return this.tokenRefreshService.refreshToken()
    .pipe(
      map(response => response.access_token),
      catchError(_ => of(null))
    );
  }

  private checkUrl(url: string): boolean {
    if (this.moduleConfig.resourceServer.customUrlValidation) {
      return this.moduleConfig.resourceServer.customUrlValidation(url);
    }

    if (this.moduleConfig.resourceServer.allowedUrls) {
      return !!this.moduleConfig.resourceServer.allowedUrls.find((u) =>
        url.toLowerCase().startsWith(u.toLowerCase())
      );
    }

    return true;
  }

  private handleErrorAndThrow(response: HttpErrorResponse, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    return throwError(() => response).pipe(
      tap({ error: async ex => await this.handleError(ex, req) })
    );
  }

  private async handleError(response: HttpErrorResponse, request: HttpRequest<any>) {
    console.log("handle error", response);

    if (!response?.status || isNaN(response.status)) {
      return;
    }

    switch (response.status) {
      case 401: {
        const header = `${response.status} - ${"Authentication Failed"}`;
        const defaultMessage = `${request.method} ${request.url} - Please log out & re-login"`;
        this.errorHandlingService.errorEvents.next({
          type: ErrorType.Htttp,
          message: header + defaultMessage,
          error: response,
        });
        break;
      }

      case 400: {
        const message = await this.errorFormatterService.format(response);
        this.errorHandlingService.errorEvents.next({
          type: ErrorType.Htttp,
          message: message,
          error: response,
        });
        break;
      }

      case 403: {
        const header = `${response.status} - ${"Authorization Failed"}`;
        const defaultMessage = `${request.method} ${request.url} - Please contact support with details of this error"`;
        this.errorHandlingService.errorEvents.next({
          type: ErrorType.Htttp,
          message: header + defaultMessage,
          error: response,
        });
        break;
      }

      default: {
        const header = `${response.status} - "Internal Server Error" `;
        const defaultMessage = await this.errorFormatterService.format(response) || "An unknown error has occurred";
        const message = " - Please try your action again, and if the problem persists please contact support";
        this.errorHandlingService.errorEvents.next({
          type: ErrorType.Htttp,
          message: header + defaultMessage + message,
          error: response,
        });
        break;
      }
    }
  }
}
