import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadMoreViewModel } from './LoadMoreViewModel';

@Component({
  selector: 'ami-load-more-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './load-more-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadMoreButtonComponent implements OnChanges {
  public canLoadMore = false;
  @Input() model?: LoadMoreViewModel<unknown>|null|undefined|boolean = null;
  @Output() loadMore = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['model']) return;

    if(this.model === true) {
      this.canLoadMore = true;
    }
    else if(this.model === false) {
      this.canLoadMore = false;
    }
    else {
    this.canLoadMore = 
      this.model?.canLoadMore === true 
      && this.model?.isLoading === false;
    }
  }

  public onLoadMore() {
    this.loadMore?.emit();
  }
}
