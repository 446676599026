import { Injectable } from "@angular/core";
import { NgbDateAdapter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { MODEL_DATE_FORMAT } from "./date-format.constants";

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class NgbDateCustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '-';

  fromModel(value: string | Date | null): NgbDateStruct | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'string' || value instanceof String) {
      return this.fromStringModel(value as string);
    }

    if (value instanceof Date) {
      return this.fromDateModel(value as Date);
    }

    throw Error("Value is not supported");
  }

  fromDateModel(date: Date): NgbDateStruct | null {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    }
  }

  private fromStringModel(value: string) {
    const date = value.split(this.DELIMITER);
    return {
      year: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      day: parseInt(date[2], 10)
    };
  }

  toModel(date: NgbDateStruct | null): string | null {
    if (date) {
      const newDate = moment();
      newDate.set('year', date.year);
      newDate.set('month', date.month - 1);
      newDate.set('date', date.day);

      return newDate.format(MODEL_DATE_FORMAT);
    }

    return null;
  }
}
