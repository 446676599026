import { Injectable } from "@angular/core";

export const nxApiBaseUrl: string = process.env['NX_API_BASE_URL']!;
export const showTestBanner: boolean = JSON.parse(process.env['NX_APP_SHOW_TEST_BANNER']!);
export const applicationInsightsInstrumentationKey: string = process.env['NX_APP_APPLICATION_INSIGHTS_INSTRUMENTATION_KEY']!;
export const appVersion: string = process.env['NX_REACT_APP_AMI_VERSION']!;

@Injectable({
  providedIn: 'root'
})
export class AppSettings {
  readonly showTestBanner: boolean = showTestBanner;
  readonly nxApiBaseUrl = nxApiBaseUrl;
}
