import { Directive, ElementRef, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: '[formControlName]',
  standalone: true
})
export class FormControlNameDirective implements OnInit {
  get control() {
    return this.controlDir.control;
  }

  constructor(
    private controlDir: NgControl,
    private host: ElementRef<HTMLFormElement>) {
  }

  ngOnInit() {
    if (this.host.nativeElement) {
      this.host.nativeElement.classList.add('ami-form-control')
    }
  }
}
