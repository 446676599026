import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'ami-test-banner',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './test-banner.component.html',
  styleUrls: ['./test-banner.component.scss'],
})
export class TestBannerComponent {
  @Input() showTestBanner?: boolean;
}
