import { FormGroup } from '@angular/forms';
import { Observable, filter, map, tap } from 'rxjs';
import { FormControlType } from './FormControlType';


export function filterValidForm<T, TControl extends FormControlType<TControl>>(
  formGroup: FormGroup<TControl>
) {
  return (source: Observable<T>) => source.pipe(
    map(() => formGroup),
    tap((fg) => fg.updateValueAndValidity()),
    filter((fg) => fg.valid)
  );
}
