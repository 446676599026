import { userHasPermission } from "./hasPermission"

export class User {
  public userId: string
  public fullName: string
  public userType: string
  public name: string
  public email: string
  public emailVerified: boolean
  public userPermissions: string[]

  constructor(
    userId: string,
    fullName: string,
    userType: string,
    name: string,
    email: string,
    emailVerified: boolean,
    userPermissions: string[]
  ) {
    this.userId = userId
    this.fullName = fullName
    this.userType = userType
    this.name = name
    this.email = email
    this.emailVerified = emailVerified
    this.userPermissions = userPermissions
  }

  public hasPermission(permission: string | string[]): boolean {
    return userHasPermission(this, permission);
  }
}

export class DealerUser extends User {
  public dealerSettings: DealerGroupSettingsViewModel;

  constructor(userId: string,
    fullName: string,
    userType: string,
    name: string,
    email: string,
    emailVerified: boolean,
    userPermissions: string[],
    dealerSettings: DealerGroupSettingsViewModel) {
    super(userId, fullName, userType, name, email, emailVerified, userPermissions);
    this.dealerSettings = dealerSettings;
  }
}

export interface DealerGroupSettingsViewModel {
  dealerGroupId: string,
  expeditedShippingEnabled: boolean,
  isAdmin: boolean,
  defaultDealerBranchId: string,
  preferredMake: string,
  preferredMakes: Array<string>,
  dealerBranches: Array<DealerBranchSettingsViewModel>,
  authorizedDealerGroups: Array<DealerGroupSwapViewModel>
}

export interface DealerBranchSettingsViewModel {
  dealerBranchId: string,
  manageQuotes: boolean,
  acceptQuotes: boolean
}

export interface DealerGroupSwapViewModel {
  id: string,
  name: string,
  isDealerAdmin: boolean
}
