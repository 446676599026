import { CanDeactivate, CanDeactivateFn, UrlTree } from '@angular/router';
import { Injectable, inject, ComponentFactoryResolver } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';

export interface HasUnsavedChangesComponent {
    hasUnsavedChanges(): boolean;
}

export const unsavedChangesGuard : CanDeactivateFn<HasUnsavedChangesComponent> = (component: HasUnsavedChangesComponent) => {
  if (component.hasUnsavedChanges()) {
    const modalService = inject(NgbModal);
    const modalRef = modalService.open(ModalConfirmComponent);

    return modalRef.componentInstance.confirmed.asObservable();
  }

  return true;
};