import { actionSignalWithErrorAndLoading } from '@ami/angular/core/http';
import { AmiSiteType } from '@ami/angular/core/ui';
import { Inject, Injectable, Signal, computed, effect, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { OAuthEvent, OAuthService } from 'angular-oauth2-oidc';
import { LocalStorageService } from 'ngx-webstorage';
import { from } from 'rxjs';
import { AMI_SITE_TYPE } from './../../../../ui/src/lib/ami-environments/ami-site-type-injection-token';
import { StoredUser } from './stored-user';
import { DealerUser, User } from './user';
import { UserLoginInput } from './user-login.input';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class UserService {
  private amiClaimBase = 'http://schemas.ami.com/';
  private permissionClaimKey = 'Permission/';
  private key = 'current_user';

  private oauthService = inject(OAuthService);
  private localStorage = inject(LocalStorageService);

  private loginAction = actionSignalWithErrorAndLoading<UserLoginInput, object>(input => from(this.doLogin(input)));
  private loginActionInput = this.loginAction.input;

  public user: Signal<User | DealerUser | null> = computed(() => {
    return this.userProfileLoaded() || this.loginAction.completed() || this.tokenRecived() ? this.getUser() : this.getUserFromStorage();
  });
  public errorAndLoading = this.loginAction;

  public logout(): void {
    this.oauthService.logOut();
  }

  public login(input: UserLoginInput): void {
    this.loginActionInput.set(input);
  }

  // TODO: should use those commented code below and watch for this.loadUserProfileAction.completed() instead
  userProfileLoaded = signal<boolean>(false);
  public async loadUserProfile() {
    this.userProfileLoaded.set(false);
    await this.oauthService.loadUserProfile();
    this.userProfileLoaded.set(true);
  }

  // public loadUserProfile() {
  //   this.loadUserProfileAction.input.set(true);
  // }

  // private loadUserProfileAction = actionSignalWithErrorAndLoading<boolean, object>(() => from(this.doLoadUserProfile()));
  // private async doLoadUserProfile() {
  //   return await this.oauthService.loadUserProfile();
  // }

  private async doLogin(input: UserLoginInput) {
    if (this.oauthService.discoveryDocumentLoaded === false) {
      await this.oauthService.loadDiscoveryDocument();
    }

    return await this.oauthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(input.username, input.password);
  }

  private oAuthEvents = toSignal(this.oauthService.events);
  private tokenRecived = computed(() => {
    const event = this.oAuthEvents();
    const eventType = event?.type;

    return eventType === 'token_received' ? event : undefined;
  });

  getClaimValue = (claims: Record<string, any>, key: string) => claims[key];
  getAmiClaimValue = (claims: Record<string, any>, key: string) => this.getClaimValue(claims, `${this.amiClaimBase}${key}`);

  private getUser(): User | DealerUser | null {
    const claims = this.oauthService.getIdentityClaims();

    if (claims === null)
      return null;

    const getClaimValue = (key: string) => claims[key];
    const getAmiClaimValue = (key: string) => getClaimValue(`${this.amiClaimBase}${key}`);

    const user = new User(
      getAmiClaimValue('UserId'),
      getAmiClaimValue('UserFullName'),
      getAmiClaimValue('UserType'),
      getClaimValue('name'),
      getClaimValue('email'),
      getClaimValue('email_verified'),
      getAmiClaimValue('Permissions')?.split('|') || [],
    );

    if (this.siteType === AmiSiteType.Management) {
      return user;
    } else {
      const dealerUser = user as unknown as DealerUser;
      const dealerSettings = getAmiClaimValue('DealerSettings');
      dealerUser.dealerSettings = dealerSettings ? JSON.parse(dealerSettings) : undefined;

      return dealerUser;
    }
  }

  public isAuthenticated() {
    return this.user() !== null;
  }

  constructor(@Inject(AMI_SITE_TYPE) public siteType: AmiSiteType) {
    effect(() => {
      const user = this.user();
      if (user) {
        this.updateUserInStorage(user);
      }
    }, { allowSignalWrites: true });

    this.oauthService.events
      .pipe(untilDestroyed(this))
      .subscribe((event: OAuthEvent) => {
        if (event?.type === 'token_received' && this.user()) {
          this.updateUserInStorage(this.user());
        }
      })
  }

  private getUserFromStorage(): User | DealerUser | null {
    const accessToken = this.oauthService.getAccessToken();

    //Get user from local storage
    const storedValue = this.localStorage.retrieve(this.key) as unknown as StoredUser;

    if (!accessToken || !storedValue || !storedValue.user || !storedValue.accessToken || storedValue.accessToken !== accessToken) {
      return null;
    }

    return this.storedValueToUser(storedValue.user)
  }

  private storedValueToUser(user: User | DealerUser) {
    const u = new User(
      user.userId,
      user.fullName,
      user.userType,
      user.name,
      user.email,
      user.emailVerified,
      user.userPermissions,
    );

    if (this.siteType === AmiSiteType.Management) {
      return u;
    } else {
      return user as unknown as DealerUser;
    }
  }

  private updateUserInStorage(user: User | null) {
    if (user === null) {
      this.localStorage.clear(this.key);
      return;
    }

    const accessToken = this.oauthService.getAccessToken();
    const val: StoredUser = { user, accessToken }

    this.localStorage.store(this.key, val);
  }
}

