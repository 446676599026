import { actionSignalWithErrorAndLoading } from "@ami/angular/core/http";
import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { DealerGroupOutput } from "./dealer-group.output";

@Injectable({
  providedIn: 'root'
})
export class DealerGroupService {
  private httpClient = inject(HttpClient);

  public getDealerGroupAction() {
    return actionSignalWithErrorAndLoading((id: string) =>
      this.httpClient.get<DealerGroupOutput>(`@api/api/dealer-groups/${id}`));
  }
}
