import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../user-service/user.service';

@Injectable({ providedIn: 'root' })
export class AuthenticatedAuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isAuthenticated = this.userService.isAuthenticated();

    if (isAuthenticated === true) return true;

    this.userService.logout();
    this.router.navigate(['/public/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}
