import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ValdemortModule } from 'ngx-valdemort';
import { CardWrapperComponent } from './card-wrapper/card-wrapper.component';
import { FilterInputRowComponent } from './filter-input-row/filter-input-row.component';
import { FormDetailWrapperComponent } from './form-detail-wrapper/form-detail-wrapper.component';
import { JoinPipe } from './join.pipe';
import { ListGroupEmptyItemComponent } from './list-group-empty-item/list-group-empty-item.component';
import { ListGroupItemComponent } from './list-group-item/list-group-item.component';
import { ListGroupComponent } from './list-group/list-group.component';
import { ListViewWrapperComponent } from './list-view-wrapper/list-view-wrapper.component';
import { LoadMoreButtonComponent } from './load-more/load-more-button.component';
import { LoadingComponent } from './loading/loading.component';
import { LoadingDirective } from './loading/loading.directive';
import { ConfirmationDialogComponent } from './modals/confirmation-dialog/confirmation-dialog.component';
import { EditFormDialogComponent } from './modals/edit-form-dialog/edit-form-dialog.component';
import { ModalButtonComponent } from './modals/modal-button/modal-button.component';
import { StandardConfirmationDialogButtonComponent } from './modals/standard-confirmation-dialog-button/standard-confirmation-dialog-button.component';
import { StandardConfirmationDialogComponent } from './modals/standard-confirmation-dialog/standard-confirmation-dialog.component';
import { StandardEditFormDialogButtonComponent } from './modals/standard-edit-form-dialog-button/standard-edit-form-dialog-button.component';
import { StandardEditFormDialogComponent } from './modals/standard-edit-form-dialog/standard-edit-form-dialog.component';
import { NoResultsRowComponent } from './no-results-row/no-results-row.component';
import { RertireButtonComponent } from './rertire-button/rertire-button.component';
import { ServerValidationErrorsComponent } from './server-validation-errors/server-validation-errors.component';
import { SimpleModalConfirmComponent } from './simple-modal-confirm/simple-modal-confirm.component';
import { SimpleModalComponent } from './simple-modal/simple-modal.component';
import { TestBannerComponent } from './test-banner/test-banner.component';
import { FormControlNameDirective } from './directives/form-control-name.directive';

export const CORE_UI = [
  NoResultsRowComponent,
  LoadMoreButtonComponent,
  JoinPipe,
  CommonModule,
  ReactiveFormsModule,
  ValdemortModule,
  RouterModule,
  CardWrapperComponent,
  ListGroupComponent,
  ListGroupItemComponent,
  ListGroupEmptyItemComponent,
  LoadingComponent,
  ListViewWrapperComponent,
  FilterInputRowComponent,
  LoadingDirective,
  RertireButtonComponent,
  SimpleModalComponent,
  TestBannerComponent,
  ServerValidationErrorsComponent,
  SimpleModalConfirmComponent,
  ConfirmationDialogComponent,
  ModalButtonComponent,
  StandardConfirmationDialogComponent,
  StandardConfirmationDialogButtonComponent,
  FormDetailWrapperComponent,
  StandardEditFormDialogComponent,
  StandardEditFormDialogButtonComponent,
  EditFormDialogComponent,
  FormControlNameDirective
];
