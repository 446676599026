import { Injectable } from '@angular/core';
import { HttpBaseUrlReplacementConfig } from '../http-base-url-replacement/provide-http-base-url-replacement';

@Injectable({
  providedIn: 'root',
})
export class ApiBaseUrlService {
  static urlPrefix = '@';
  baseUrls = new Map<string, URL>();

  constructor(private httpBaseUrlReplacementConfig: HttpBaseUrlReplacementConfig) {
    this.buildBaseUrlsFromConfig();
  }

  private buildBaseUrlsFromConfig() {
    if (this.httpBaseUrlReplacementConfig.aliases && this.httpBaseUrlReplacementConfig.aliases.length) {
      this.httpBaseUrlReplacementConfig.aliases.forEach(config => {
        if (config.alias && config.baseUrl) {
          this.baseUrls.set(config.alias.toLocaleLowerCase(), new URL(config.baseUrl));
        }
      })
    }
  }

  replace(url: string) {
    if (!url.startsWith(ApiBaseUrlService.urlPrefix)) {
      return null;
    }

    const aliasWithPrefix = url.split('/')[0];
    const alias = aliasWithPrefix.slice(ApiBaseUrlService.urlPrefix.length).toLowerCase();
    const baseUrl = this.baseUrls.get(alias);

    if (!baseUrl) {
      return null;
    }

    const path = url.slice(aliasWithPrefix.length);
    return new URL(path, baseUrl).toString();
  }
}
