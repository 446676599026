import { ToastComponent } from '@ami/angular/core/toasts';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'ami-authenticated-layout',
  standalone: true,
  imports: [RouterModule, MenuComponent, ToastComponent],
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss'],
})
export class AuthenticatedLayoutComponent { }
