import { Signal, WritableSignal, computed, signal } from '@angular/core';
import { Observable, exhaustMap, filter, of, tap } from 'rxjs';
import { httpRequestStates, isErrorState, isLoadedState } from 'ngx-http-request-state';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { HttpErrorResponse } from '@angular/common/http';

export interface ActionWithErrorAndLoading<TInput, TResult> extends ErrorAndLoading {
  input: WritableSignal<TInput|null>,
  value: Signal<TResult|undefined>
}

export interface ErrorAndLoading {
  loading: Signal<boolean|undefined>,
  error: Signal<HttpErrorResponse|Error|null>,
  completed: Signal<boolean|undefined>,
}

export function actionSignalWithErrorAndLoading<TInput, TResult>(action: (input: TInput) => Observable<TResult>) : ActionWithErrorAndLoading<TInput, TResult> {
  const input = signal<TInput | null>(null, {equal: () => false });
  const input$ = toObservable(input).pipe(
    filter(x => x !== null && x !== undefined),
    // tap(x => console.log("input changed", x)), // Enable this for local debugging only
    exhaustMap(x => action(x as TInput).pipe(httpRequestStates()))
  );
  const request = toSignal(input$);
  const loading = computed(() => request()?.isLoading);
  const error = computed(() => {
    const r = request();
    return isErrorState(r) ? r.error : null;
  });
  const completed = computed(() => isLoadedState(request()));
  const value = computed(() => request()?.value);

  return { input, loading, error, completed, value };
}

export function emptyAction<TInput, TResult>(result:TResult) : ActionWithErrorAndLoading<TInput, TResult> {
  return actionSignalWithErrorAndLoading(() => of(result));
}
