import { Injectable } from '@angular/core';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { AutomaticTokenRefreshService } from './token/automatic-token-refresh.service';
import { filter, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthStartupService {

  constructor(
    private oauthService: OAuthService,
    private automaticTokenRefresh: AutomaticTokenRefreshService,
    private authConfig: AuthConfig,
    private router: Router) { }

  public async initialize(redirectOnLogoutUrl: string|null = null) {
    if(redirectOnLogoutUrl) {
      this.redirectOnLogout(redirectOnLogoutUrl);
    }

    this.oauthService.configure(this.authConfig);
    await this.oauthService.loadDiscoveryDocument();
    this.automaticTokenRefresh.setupAutomaticSilentRefresh();
  }

  private redirectOnLogout(redirectOnLogoutUrl: string) {
    this.oauthService.events.pipe(
      filter(x => x.type === 'logout'),
      tap(_ => this.router.navigateByUrl(redirectOnLogoutUrl))
    ).subscribe();
  }
}
