import { EventTypes, ToastService } from '@ami/angular/core/toasts';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbDatepicker, NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import * as moment from 'moment';
import { DISPLAY_DATE_FORMAT, MODEL_DATE_FORMAT } from './date-format.constants';
import { NgbDateCustomAdapter } from './ngb-date-custom-adapter';
import { NgbDateCustomDateParserFormatter } from './ngb-date-custom-date-parser-formatter';
import { CustomDatepickerI18n } from './ngb-date-custom-i18n';
import { I18n } from './ngb-date-i18n';

@UntilDestroy()
@Component({
  selector: 'ami-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbModule,FormsModule, ReactiveFormsModule,],
  providers: [
    { provide: NgbDateAdapter, useClass: NgbDateCustomAdapter },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomDateParserFormatter },
    I18n,
    { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DatePickerComponent
    }
  ]
})
export class DatePickerComponent implements OnInit, ControlValueAccessor {
  @ViewChild('datepicker', { static: true }) instance!: NgbDatepicker;
  @ViewChild('dpInput', { static: true }) dpInput!: ElementRef;
  @Output() invalidDateSelectedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() minDate!: any;
  @Input() maxDate!: any;
  @Input() startDate!: any;

  @Input() placeholder: string = DISPLAY_DATE_FORMAT;
  @Input() markDisabled!: (date: NgbDate, current?: {
    year: number;
    month: number;
  }) => boolean;

  invalidDate = false;

  set language(language: string) {
    this._i18n.language = language;
  }

  get language() {
    return this._i18n.language;
  }

  get today(): string {
    return this.dateAdapter.toModel(this.calendar.getToday()) ?? "";
  }

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private dateAdapter: NgbDateAdapter<string>,
    private _i18n: I18n,
    private toastService: ToastService) {
  }

  ngOnInit(): void {
    this.setDefaultValuesToDatePicker();
  }

  writeValue(obj: any): void {
    this.instance.writeValue(obj);
  }

  registerOnChange(onChange: any): void {
    this.instance.registerOnChange(onChange);
  }

  registerOnTouched(onTouched: any): void {
    this.instance.registerOnTouched(onTouched);
  }

  private setDefaultValuesToDatePicker() {
    // this.model = NgbDate.from(this.dateAdapter.fromModel(this.today));
  }

  inputChanges() {
    if (this.dpInput) {
      this.invalidDate = false;
      const value = this.dpInput.nativeElement.value;

      if (!value) {
        this.writeValue(null);
        return;
      }

      const formattedDate = this.formatter.parse(value);
      if (!formattedDate) {
        this.showError();
        return;
      }

      const userInputtedDate = this.ngbDateStructToMomentDate(formattedDate);

      if (this.minDate) {
        const minDate = this.ngbDateStructToMomentDate(this.minDate);
        if (userInputtedDate < minDate) {
          this.showError();
          return;
        }
      }

      if (this.maxDate) {
        const maxDate = this.ngbDateStructToMomentDate(this.maxDate);
        if (userInputtedDate > maxDate) {
          this.showError();
          return;
        }
      }

      const displayDate = this.formatter.format(formattedDate);
      this.dpInput.nativeElement.value = displayDate;

      const modelDate = moment(displayDate, DISPLAY_DATE_FORMAT).format(MODEL_DATE_FORMAT);
      this.writeValue(modelDate);
    }
  }

  private ngbDateStructToMomentDate(date: NgbDateStruct) {
    const newDate = moment();
    newDate.set('year', date.year);
    newDate.set('month', date.month - 1);
    newDate.set('date', date.day);

    return newDate;
  }

  private showError() {
    this.invalidDate = true;
    this.writeValue(null);
    this.invalidDateSelectedEvent.emit(true);

    this.toastService.queueToast("Invalid Date", EventTypes.Error);
  }

  @HostListener('focusout', ['$event'])
  onFocusout(event: any) {
    if (this.invalidDate) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
