import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { makeEnvironmentProviders } from "@angular/core";
import { ApiBaseUrlHttpInterceptor } from "../api-routing/api-base-url-http-interceptor";

export function provideHttpBaseUrlReplacement(config: HttpBaseUrlReplacementConfig) {
  return makeEnvironmentProviders([
    { provide: HttpBaseUrlReplacementConfig, useValue: config },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiBaseUrlHttpInterceptor,
      multi: true
    },
  ])
}

export abstract class HttpBaseUrlReplacementConfig {
  aliases?: Array<HttpBaseUrlAliasConfig>
}

export abstract class HttpBaseUrlAliasConfig {
  alias?: string;
  baseUrl?: string;
}
