import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseUrlService } from './api-base-url.service';

@Injectable()
export class ApiBaseUrlHttpInterceptor implements HttpInterceptor {

  constructor(
    private apiBaseUrlService: ApiBaseUrlService) {
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const newUrl = this.apiBaseUrlService.replace(request.url);

    if (newUrl) {
      request = request.clone({
        url: newUrl,
      });
    }

    return next.handle(request)
  }
}
