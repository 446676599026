import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorHandlingService } from './error-handling.service';
import { ErrorType } from './error-type';
import { ErrorFormatterService } from '../formatting/error-formatter.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandling implements ErrorHandler {
  constructor(private errorHandlingService: ErrorHandlingService, private errorFormatterService: ErrorFormatterService) {}

  async handleError(evError: any): Promise<void> {
    const message = await this.errorFormatterService.format(evError)
    this.errorHandlingService.errorEvents.next({
      type: ErrorType.App,
      message: message,
      error: evError,
    });

    console.error(evError);
  }
}
