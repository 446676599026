import { BehaviorSubject, Observable, combineLatest, filter, map, scan, switchMap, tap, withLatestFrom } from 'rxjs';
import { httpRequestStates, isErrorState, isLoadedState, isLoadingState, loadingState, HttpRequestState } from 'ngx-http-request-state';
import { LoadMoreViewModel } from './LoadMoreViewModel';

export function loadMoreSource<TInput, TOutput>(
  getItems: (value: TInput, skip: number, top: number) => Observable<TOutput[]>,
  startingInput : TInput,
  pageSize = 100
) {
  return new LoadMoreSource(getItems, startingInput, pageSize);
}

export class LoadMoreSource<TInput, TOutput> {

  private pageSize : number;

  private input = new BehaviorSubject<TInput|null>(null);
  private input$ = this.input.asObservable().pipe(
    filter((i) : i is TInput => !!i)
  );

  private skip = new BehaviorSubject<number>(0);
  
  private loadMoreTrigger = new BehaviorSubject<void>(undefined);

  public items$: Observable<LoadMoreViewModel<TOutput>> = combineLatest([this.loadMoreTrigger, this.input$]).pipe(
    map(([,i]) => i),
    withLatestFrom(this.skip),
    switchMap(([i, s]) => this.getItems(i, s, this.pageSize).pipe(httpRequestStates())),
    withLatestFrom(this.skip),
    scan((acc, [currResults, currOffset]) => {
      const currItems = currResults.value ?? [];
      const accItems = acc.value ?? [];

      if(isLoadedState(currResults)) {
        const items = currOffset === 0 ? currItems : [...accItems, ...currItems];
        return this.toLoadMoreViewModel(currResults, items, currItems.length >= this.pageSize);
      }

      if(isLoadingState(currResults)) {
        const items = currOffset === 0 ? [] : accItems;
        return this.toLoadMoreViewModel(currResults, items, false);
      }

      if(isErrorState(currResults)) {
        return this.toLoadMoreViewModel(currResults, acc.value, acc.canLoadMore);
      }
      
      throw "No compatible states";
    },
    { ...loadingState(new Array<TOutput>()), canLoadMore:false} as LoadMoreViewModel<TOutput>),
    tap(x => {
      if(isLoadedState(x) && x.value)
      {
        this.skip.next(x.value.length) //Update offset for next time
      }
    })
  );

  private toLoadMoreViewModel(state:HttpRequestState<TOutput[]>, value:TOutput[]|undefined, canLoadMore:boolean) : LoadMoreViewModel<TOutput> {
    return {
      ...state,
      value: value,
      canLoadMore: canLoadMore
    };
  }

  constructor(
    private getItems: (
      value: TInput,
      skip: number,
      top: number
    ) => Observable<TOutput[]>,
    startingInput : TInput,
    pageSize = 100
  ) {
    this.pageSize = pageSize;
    this.input.next(startingInput);
    this.loadMoreTrigger.next();
  }

  public updateInput(input: TInput) {
    this.skip.next(0);
    this.input.next(input);
  }

  public loadMore() {
    this.loadMoreTrigger.next();
  }
}
