import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges, ViewContainerRef
} from '@angular/core';
import { HttpRequestState, isLoadingState } from 'ngx-http-request-state';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';


const OVERLAY_CLASS = 'loading-overlay';

// This directive places an overlay with a loading spinner over its host element
// if isLoading equals to true and hides the overlay when isLoading becomes false.
@Directive({
  selector: '[ami-loading]',
  standalone: true
})
export class LoadingDirective implements OnChanges {
  @Input('ami-loading') amiLoading?: HttpRequestState<unknown> | null | undefined | boolean = null;
  @Input('ami-loading-text') loadingText = '';
  @Input('loadingOverlayPosition') loadingOverlayPosition = 'center';
  @Input('isDisabled') isDisabled = false;

  protected overlayElement!: HTMLDivElement;
  protected spinnerElement!: HTMLDivElement;
  protected hostElement!: HTMLDivElement | HTMLButtonElement;
  protected hostElementOriginalTextContent!: string | null;

  private buttonSpinnerElement: Element | null = null;

  constructor(
    protected readonly elementRef: ElementRef,
    protected readonly renderer: Renderer2,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly viewContainerRef: ViewContainerRef,
    protected readonly componentFactoryResolver: ComponentFactoryResolver,
  ) {
    this.hostElement = this.elementRef.nativeElement;
    this.hostElement.style.position = 'relative';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((!this.overlayElement || !this.spinnerElement) && this.hostElement.nodeName != "BUTTON") {
      this.init();
    }

    if (changes['amiLoading']) {
      const val = changes['amiLoading'].currentValue;

      const isLoadingValue = typeof val == 'boolean'
        ? val as boolean
        : val !== null && val !== undefined && isLoadingState(val);

      if (isLoadingValue) {
        this.addLoadingIndicator();
      }
      else {
        this.removeLoadingIndicator();
      }

      this.changeDetectorRef.markForCheck();
    }

    this.setButtonStatus();
  }

  protected addLoadingIndicator(): void {
    if (this.loadingText) {
      this.hostElementOriginalTextContent = this.hostElement.textContent;
      this.hostElement.textContent = this.loadingText;
    }

    switch (this.hostElement.nodeName) {
      case 'DIV':
        this.renderer.appendChild(this.hostElement, this.overlayElement);
        this.renderer.appendChild(this.overlayElement, this.spinnerElement);
        break;

      case 'BUTTON':
        if (!this.buttonSpinnerElement) {
          this.buttonSpinnerElement = this.renderer.createElement('span');
          this.renderer.addClass(this.buttonSpinnerElement, "spinner-border");
          this.renderer.addClass(this.buttonSpinnerElement, "spinner-border-sm");
          this.renderer.addClass(this.buttonSpinnerElement, "ms-1");

          this.renderer.setAttribute(this.buttonSpinnerElement, "role", "status");
          this.renderer.setAttribute(this.buttonSpinnerElement, "aria-hidden", "true");

        }

        this.hostElement.setAttribute('disabled', 'disabled');

        if (this.buttonSpinnerElement) {
          this.hostElement.insertAdjacentElement('beforeend', this.buttonSpinnerElement);
        }

        break;
    }
  }

  protected removeLoadingIndicator(): void {
    if (this.hostElementOriginalTextContent) {
      this.hostElement.textContent = this.hostElementOriginalTextContent;
    }

    switch (this.hostElement.nodeName) {
      case 'DIV':
        if (this.hostElement.contains(this.overlayElement))
          this.renderer.removeChild(this.hostElement, this.overlayElement);
        if (this.overlayElement.contains(this.spinnerElement))
          this.renderer.removeChild(this.overlayElement, this.spinnerElement);
        break;

      case 'BUTTON':
        this.hostElement.removeAttribute('disabled');
        this.setButtonStatus();
        if (this.buttonSpinnerElement !== null && this.hostElement.contains(this.buttonSpinnerElement)) {
          this.hostElement.removeChild(this.buttonSpinnerElement);
        }

        break;
    }

    this.viewContainerRef.clear();
  }

  protected init(): void {
    this.initOverlayElement();
    this.initSpinnerComponent();
  }

  protected initSpinnerComponent(): void {
    const spinnerComponentFactory = this.componentFactoryResolver.resolveComponentFactory(LoadingSpinnerComponent);
    const spinnerComponent = this.viewContainerRef.createComponent(spinnerComponentFactory);
    this.spinnerElement = spinnerComponent.location.nativeElement;
  }

  protected initOverlayElement(): void {
    this.overlayElement = this.renderer.createElement('div');
    this.renderer.addClass(this.overlayElement, OVERLAY_CLASS);

    const loadingOverlayPosition = this.loadingOverlayPosition.toLowerCase();
    switch (loadingOverlayPosition) {
      case 'end':
        this.renderer.addClass(this.overlayElement, 'loading-overlay-spinner-end');
        break;
    }
  }

  private setButtonStatus() {
    if (this.isDisabled) {
      this.hostElement.setAttribute('disabled', 'disabled');
    } else {
      this.hostElement.removeAttribute('disabled');
    }
  }
}
