import { Injectable, Injector, effect, inject, signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { OAuthService, OAuthStorage } from "angular-oauth2-oidc";
import { Subscription, filter, firstValueFrom, take } from "rxjs";
import { SingletonTokenRefreshService } from "./singleton-token-refresh.service";


@Injectable({ providedIn: 'root' })
export class AutomaticTokenRefreshService {
  private oauthService = inject(OAuthService);
  private refreshService = inject(SingletonTokenRefreshService);
  private oauthStorageService = inject(OAuthStorage);
  

  constructor(private injector: Injector) { }

  private minTimeoutFactor = 0.75;
  private maxTimeoutFactor = 0.9;

  protected automaticRefreshSubscription?: Subscription;

  private tokenReceived = toSignal(this.oauthService.events.pipe(
    filter((e) => e.type == 'token_received')
  ));

  private expirationDate = signal(this.getExpirationDate());

  private getExpirationDate(): number | null {
    if (!this.oauthService.getAccessToken()) return null;

    return this.oauthService.getAccessTokenExpiration();
  }

  public setupAutomaticSilentRefresh(): void {
    effect(() => {
      if (this.tokenReceived()) {
        const exp = this.oauthService.getAccessTokenExpiration();
        this.expirationDate.set(exp);
      }
    }, { allowSignalWrites: true, injector: this.injector });

    effect((onCleanup) => {
      const exp = this.expirationDate();

      if (!exp) return;

      const storedAt = this.getAccessTokenStoredAt();
      const timeoutFactor = this.getRandomTimeoutFactor();
      const timeout = this.calcTimeout(storedAt, exp, timeoutFactor);

      const timer = setTimeout(async (expectedExpiration: number) => {
        if (!this.oauthService.getAccessToken()) return;

        const expiration = this.oauthService.getAccessTokenExpiration();

        if (expiration == expectedExpiration) {
          console.debug("Refreshing", expiration, expectedExpiration);
          this.refresh(expectedExpiration);
        }
        else {
          console.debug("Skipping Refresh", expiration, expectedExpiration);
          //Access token was updated elsewhere
          this.expirationDate.set(expiration);
        }
      }, timeout, exp);

      onCleanup(() => {
        clearTimeout(timer);
      });
    }, { injector: this.injector });
  }

  protected async refresh(expectedExpiration: number) {
    
    try {
      await firstValueFrom(this.refreshService.refreshToken());
    }
    catch (ex) {
      const expiration = this.oauthService.getAccessTokenExpiration();

      //Most likely refresh token was changed elsewhere
      if (expiration !== expectedExpiration && this.oauthService.hasValidAccessToken() === true) {
        this.expirationDate.set(expiration);
      }
      else {
        console.warn("Pausing automatic token refresh");
      }
    }
  }

  protected getAccessTokenStoredAt(): number {
    return parseInt(
      this.oauthStorageService.getItem('access_token_stored_at') ?? '',
      10
    );
  }

  protected getRandomTimeoutFactor() {
    return this.getRandomNumber(
      this.minTimeoutFactor,
      this.maxTimeoutFactor,
      2,
      true
    );
  }

  private getRandomNumber(
    a: number,
    b: number,
    digits = 0,
    includeAB = true
  ): number {
    const multiplier = digits >= 1 ? Math.pow(10, digits) : 1;
    const border = includeAB ? 0 : 1;
    const start = Math.min(a, b) * multiplier + border;
    const space = Math.max(a, b) * multiplier - start - border;
    const int = Math.floor(start + Math.random() * (space + 1));
    return int / multiplier;
  }

  protected calcTimeout(
    storedAt: number,
    expiration: number,
    timeoutFactor: number
  ): number {
    const now = Date.now();
    const delta = (expiration - storedAt) * timeoutFactor - (now - storedAt);
    const duration = Math.max(0, delta);
    const maxTimeoutValue = 2_147_483_647;
    return duration > maxTimeoutValue ? maxTimeoutValue : duration;
  }
}

