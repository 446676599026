import { Observable } from 'rxjs';
import { ActionWithErrorAndLoading } from '@ami/angular/core/http';
import { LoadMoreSource } from './load-more-source.function';
import { toSignal } from '@angular/core/rxjs-interop';
import { Signal, computed, effect, signal } from '@angular/core';
import { isLoadedState } from 'ngx-http-request-state';

export interface LoadMoreAction<TInput, TOutput> extends ActionWithErrorAndLoading<TInput, TOutput[]> {
    canLoadMore: Signal<boolean | undefined>,
    loadMore(): void;
}

class LoadMoreActionImpl<TInput, TOutput> implements LoadMoreAction<TInput, TOutput> {

    items = toSignal(this.source.items$);

    input = signal<TInput | null>(null, { equal: () => false });
    loading = computed(() => this.items()?.isLoading);
    error = computed(() => this.items()?.error ?? null);
    completed = computed(() => isLoadedState(this.items()));
    value = computed(() => this.items()?.value);
    canLoadMore = computed(() => this.items()?.canLoadMore);

    constructor(private source:LoadMoreSource<TInput, TOutput>) {
        effect(() => {
            const i = this.input();
            if(i !== null) {
                source.updateInput(i);
            }
        }, { allowSignalWrites:true });
    }

    loadMore(): void {
        this.source.loadMore();
    }
}

export function loadMoreAction<TInput, TOutput>(
    getItems: (value: TInput, skip: number, top: number) => Observable<TOutput[]>,
    startingInput : TInput,
    pageSize = 100
) : LoadMoreAction<TInput, TOutput> {
    return new LoadMoreActionImpl<TInput, TOutput>(new LoadMoreSource(getItems, startingInput, pageSize));
  }
