import { ControlValueAccessor } from "@angular/forms";
import { NgbTypeahead } from "@ng-bootstrap/ng-bootstrap";

export abstract class TypeAheadFormControlComponentBase implements ControlValueAccessor {
  abstract instance: NgbTypeahead;

  protected transformToFormModel(value:any) : any {
    return value;
  }

  onChange = (item: any) => { };

  writeValue(obj: any): void {
    if (this.instance.inputFormatter === undefined) {
      this.instance.inputFormatter = this.inputFormatter;
    }

    this.instance.writeValue(obj);
    this.onWriteValue(obj);

    setTimeout(() => {
      this.instance.selectItem.emit({ item: obj, preventDefault: () => false });
    }, 100);
  }

  protected onWriteValue(obj: any): void { }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
    this.instance.registerOnChange(value => {
      const transformed = this.transformToFormModel(value);
      onChange(transformed);
    });
  }

  registerOnTouched(onTouched: any): void {
    this.instance.registerOnTouched(onTouched);
  }

  abstract inputFormatter: (result: any) => string;

  clearSelectedItem() {
    this.instance.selectItem.emit({ item: null, preventDefault: () => false });
  }

  togglePopupResult(searching: boolean) {
    if (this.instance) {
      const popup: any = document.getElementById(this.instance.popupId);
      if (popup) {
        popup.style.visibility = searching === true ? "hidden" : "visible";
      }
    }
  }
}
