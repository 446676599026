import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorFormatterService {
  async format(error: HttpErrorResponse | Error | undefined): Promise<string> {
    if (error == undefined) {
      return 'An error has occurred';
    }
    if (error instanceof HttpErrorResponse) {
      return await this.formatHttpErrorResponse(error as HttpErrorResponse);
    } else {
      return error.message;
    }
  }

  public async formatHttpErrorResponse(response: HttpErrorResponse) {
    let errorMessage = '';
    if (response.error) {
      if (Array.isArray(response.error)) {
        errorMessage = this.formatArray(response.error);
      } else if (response.error instanceof Blob) {
        errorMessage = await this.formatErrorFromBlobResponse(response);
      } else {
        if (response.status === 500) {
          errorMessage = response.message;
        } else {
          errorMessage = response.error?.message || response.error?.exceptionMessage || response.error?.errorMessage || response.error.errors || response.error;
        }

        if (Array.isArray(errorMessage)) {
          errorMessage = this.formatArray(errorMessage);
        } else if (typeof errorMessage === 'object') {
          if (errorMessage["error_description"]) {
            errorMessage = errorMessage["error_description"];
          } else {
            let validationErrorMessages = "<ul class='mb-0'>";
            Object.keys(errorMessage).forEach(function (key: any) {
              validationErrorMessages += '<li>' + (errorMessage[key] + '</li>');
            });
            validationErrorMessages += "</ul>"

            errorMessage = validationErrorMessages;
          }
        }
      }
    }
    
    return errorMessage;
  }

  async formatErrorFromBlobResponse(response: HttpErrorResponse) {
    let jsonError: any;
    switch (response.error.type) {
      case "text/plain":
        return await (new Response(response.error)).text();
      default:
        jsonError = await (new Response(response.error)).json();

        if (Array.isArray(jsonError)) {
          return jsonError.map((x) => x.errorMessage || x.message || x).join('\r\n');
        } else {
          return jsonError?.message;
        }
    }
  }

  public formatArray(errors: any[]) {
    if (errors.length == 1) return (errors[0].errorMessage || errors[0].message || errors[0]);
    let errorList = "<ul class='mb-0'>";
    errors.forEach(error => {
      const errorText = (error.errorMessage || error.message || error);
      if (errorText) errorList += "<li>" + errorText + "</li>"
    });
    errorList += "</ul>"
    return errorList;
  }
}
