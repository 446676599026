import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { DISPLAY_DATE_FORMAT, MODEL_DATE_FORMAT } from "./date-format.constants";

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class NgbDateCustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let formattedDate = value;

      if (value.indexOf(this.DELIMITER) === -1) {
        formattedDate = moment(value, DISPLAY_DATE_FORMAT).format(MODEL_DATE_FORMAT);
      }

      if (formattedDate === 'Invalid date' || !moment(formattedDate, MODEL_DATE_FORMAT, true).isValid())
        return null;

      const date = formattedDate.split(this.DELIMITER);
      return {
        year: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        day: parseInt(date[2], 10)
      };

    }

    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      const newDate = moment();
      newDate.set('year', date.year);
      newDate.set('month', date.month - 1);
      newDate.set('date', date.day);

      return newDate.format(DISPLAY_DATE_FORMAT);
    }

    return '';
  }
}
