import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalDismissReasons, NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ami-modal-confirm',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './modal-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalConfirmComponent {
  @Input() title = 'Unsaved Changes';
  @Input() confirmButtonText = 'Stay on this page';
  @Input() confirmButtonStyle = 'btn-primary';
  @Input() cancelButtonText = 'Leave this page';
  @Input() cancelButtonStyle = 'btn-secondary';
  @Input() contentText = 'You have unsaved changes. Are you sure you want to leave this page?';

  @Output() confirmed = new EventEmitter<boolean>();
  private modalService= inject(NgbModal)
  
  cancel() {
    this.modalService.dismissAll()
    this.confirmed.emit(true);
  }

  ok() {
    this.modalService.dismissAll();
  }

}

