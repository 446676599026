import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { EventTypes } from './event-types';
import { ToastEvent } from './toast-event';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  public toastEvents$: Observable<ToastEvent>;
  private _toastEvents$ = new Subject<ToastEvent>();

  private _dequeueToast$ = new BehaviorSubject<boolean>(false);
  public dequeueToast$ = this._dequeueToast$.asObservable();

  constructor() {
    this.toastEvents$ = this._toastEvents$.asObservable();
  }

  queueToast(message: string, eventType: EventTypes) {
    this._toastEvents$.next({
      message,
      type: eventType,
    });
  }

  dequeueToast() {
    this._dequeueToast$.next(true);
  }
}
