<div class="position-fixed top-0 end-0 p-3" style="z-index: 10000">
  <div class="toast hide" [ngClass]="{
      'text-bg-success': eventType === eventTypes.Success,
      'text-bg-danger': eventType === eventTypes.Error,
      'text-bg-light': eventType === eventTypes.Info,
      'text-bg-warning': eventType === eventTypes.Warning,
      'text-white': eventType === eventTypes.Success || eventType === eventTypes.Error
    }" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="d-flex">
      <div class="toast-body" [innerHTML]="message">
      </div>
      <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast"
        aria-label="Close"></button>
    </div>
  </div>
</div>
