import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class LoadingSpinnerComponent {
  @Input() navLinkSpinner = false;
  @Input() navLinkSpinnerActive = false;
}
