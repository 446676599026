import { map, Observable } from 'rxjs';
import { User } from './user';


export function hasPermission(permission: string | string[]) {
  return (source: Observable<User | null>) => source.pipe(
    map(u => u?.hasPermission(permission) ?? false)
  );
}

export function userHasPermission(user: User | null, permission: string | string[]) {
  const perms = (Array.isArray(permission) ? permission : [permission])
    .map((p) => p.toLocaleLowerCase());

  const userPerms = user?.userPermissions
    .map((p) => p.toLocaleLowerCase());

  const found = perms?.find((x) => userPerms?.includes(x));

  return found !== undefined;
}
