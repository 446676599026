import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRequestState, isLoadingState } from 'ngx-http-request-state';

@Component({
  selector: 'ami-no-results-row',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-results-row.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoResultsRowComponent implements OnChanges {
  public isNoResults = false;
  @Input() colspan?: number = 1;
  @Input() message?: string;
  @Input() items?: HttpRequestState<any[]>|{length:number}|null|undefined  = null;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(!changes['items']) return;
     
    this.isNoResults = this.getIsNoResults();
  }

  getIsNoResults(): boolean {
    if(this.items === null || this.items === undefined) {
      return false;
    }
    
    if('length' in this.items) {
      return this.items.length === 0;
    }
    
    if(isLoadingState(this.items)) {
      return false;
    }

    if('value' in this.items) {
      return this.items.value?.length === 0;
    }

    if('value' in this.items) {
      return this.items.value?.length === 0;
    }
    
    return false;
  }
}